import { RouteRecordRaw } from 'vue-router';

const Index = () => import("@/pages/candidate/IndexPage.vue");
const About = () => import("@/pages/candidate/AboutPage.vue");
const Login = () => import("@/pages/candidate/LoginPage.vue");
const ForgotPassword = () => import("@/pages/candidate/ForgotPassword.vue");
const ResetPassword = () => import("@/pages/candidate/ResetPassword.vue");
const CompletedResetPassword = () => import("@/pages/candidate/CompletedResetPassword.vue");
const BasicInformation = () => import("@/pages/candidate/BasicInformation.vue");
const WorkHistory = () => import("@/pages/candidate/WorkHistory.vue");
const FinalEducation = () => import("@/pages/candidate/Education.vue");
const ExperienceCompany = () => import("@/pages/candidate/ExperienceCompany.vue");
const Skill = () => import("@/pages/candidate/Skill.vue");
const JobHistories = () => import("@/pages/candidate/JobHistory.vue");
const AdditionalBasic = () => import("@/pages/candidate/AdditionalBasic.vue");
const CompletedBasicInfo = () => import("@/pages/candidate/CompletedBasicInfo.vue");
const OptionalInformation = () => import("@/pages/candidate/OptionalInformation.vue");
const EditProfile = () => import("@/pages/candidate/EditProfile.vue");

const Completed = () => import("@/pages/candidate/Completed.vue");
const RegisterComplete = () => import("@/pages/candidate/CompleteRegisterEmail.vue");
const ForgotPasswordComplete = () => import("@/pages/candidate/CompleteForgotPassword.vue");
const VerifyPage = () => import('@/pages/candidate/VerifyPage.vue');
const VerifyResetPasswordPage = () => import('@/pages/candidate/VerifyResetPasswordPage.vue');
const OfferReport = () => import('@/pages/candidate/offer_report/OfferReport.vue');
const CompletedOfferReport = () => import('@/pages/candidate/offer_report/Completed.vue')
const JobSearch = () => import('@/pages/candidate/JobSearch.vue');
const FAQ = () => import('@/pages/candidate/FAQPage.vue');
const TermAndCondition = () => import('@/pages/candidate/TermAndConditionPage.vue');
const PrivacyPolicy = () => import("@/pages/candidate/PrivacyPolicyPage.vue");
const NotFoundPage = () => import("@/pages/candidate/NotFoundPage.vue");
const BasicInformationUpdatePassword = () => import('@/pages/candidate/register/basic_information/UpdatePassword.vue');
const JobDirection = () => import('@/pages/candidate/job/JobDirection.vue');
const SignUpDirection = () => import('@/pages/candidate/register/basic_information/SignUpDirection.vue');
const CpContact = () => import("@/pages/candidate/CpContact.vue");
const CpContactDone = () => import("@/pages/candidate/CpContactDone.vue");
const Contact = () => import("@/pages/candidate/Contact.vue");
const ContactDone = () => import("@/pages/candidate/ContactDone.vue");
const TeaserThanks = () => import("@/pages/teaser/thanks.vue");
const Partners = () => import("@/pages/partners.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: '/signup/new',
    components: {
      default: SignUpDirection,
    }
  },
  {
    path: '/candidate/signup/update_password',
    components: {
      default: BasicInformationUpdatePassword,
    }
  },
  {
    path: "/candidate/about",
    name: "about",
    components: {
      default: About,
    },
  },
  {
    path: "/candidate/login",
    name: "login",
    components: {
      default: Login,
    },
  },
  {
    path: "/candidate/forgot_password",
    name: "forgot_password",
    components: {
      default: ForgotPassword,
    },
  },
  {
    path: "/candidate/reset_password",
    name: "reset_password",
    components: {
      default: ResetPassword,
    },
  },
  {
    path: "/candidate/reset_password/completed",
    name: "reset_password_completed",
    components: {
      default: CompletedResetPassword,
    },
  },
  {
    path: "/candidate/basic_infomation",
    name: "basic-infomation",
    components: {
      default: BasicInformation,
    },
  },
  {
    path: "/candidate/job_search",
    name: "job-search",
    components: {
      default: JobSearch,
    }
  },
  {
    path: "/candidate/work_histories",
    name: "work_histories",
    components: {
      default: WorkHistory,
    },
  },
  {
    path: "/candidate/education",
    name: "education",
    components: {
      default: FinalEducation,
    },
  },
  {
    path: "/candidate/experience",
    name: "experience",
    components: {
      default: ExperienceCompany,
    },
  },
  {
    path: "/candidate/skill",
    name: "skill",
    components: {
      default: Skill,
    },
  },
  {
    path: "/candidate/job_history",
    name: "job-history",
    components: {
      default: JobHistories,
    },
  },
  {
    path: '/candidate/additional',
    name: 'additional',
    components: {
      default: AdditionalBasic,
    },
  },
  {
    path: '/candidate/optional_information',
    name: 'optional_information',
    components: {
      default: OptionalInformation
    }
  },
  {
    path: '/candidate/completed_information',
    name: 'completed_information',
    components: {
      default: CompletedBasicInfo,
    },
  },
  {
    path: '/candidate/completed',
    name: 'completed',
    components: {
      default: Completed,
    },
  },
  {
    path: '/teaser/thanks',
    name: 'teaser_thanks',
    components: {
      default: TeaserThanks,
    },
  },
  {
    path: '/candidate/register_complete',
    name: 'register_complete',
    components: {
      default: RegisterComplete
    }
  },
  {
    path: '/candidate/forgot_password_complete/:email',
    name: 'forgot_password_complete',
    components: {
      default: ForgotPasswordComplete
    },
    props: true
  },
  {
    path: '/profiles/edit',
    name: 'edit_profile',
    components: {
      default: EditProfile
    }
  },
  {
    path: '/candidate/verify',
    name: 'verify',
    components: {
      default: VerifyPage
    }
  },
  {
    path: '/candidate/verify_reset_password',
    name: 'verify_reset_password',
    components: {
      default: VerifyResetPasswordPage
    }
  },
  {
    path: '/profiles/offer_report',
    name: 'offer_report',
    components: {
      default: OfferReport
    }
  },
  {
    path: '/profiles/offer_report/fin',
    name: 'completed_offer_report',
    components: {
      default: CompletedOfferReport
    }
  },
  {
    path: '/faq',
    name: 'faq',
    components: {
      default: FAQ
    }
  },
  {
    path: '/terms',
    name: 'terms',
    components: {
      default: TermAndCondition
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    components: {
      default: PrivacyPolicy
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    components: {
      default: NotFoundPage
    }
  },
  {
    path: '/jobs',
    name: 'jobs_direction',
    components: {
      default: JobDirection
    }
  },
  {
    path: '/cp-contact',
    name: 'cp_contact',
    components: {
      default: CpContact
    }
  },
  {
    path: '/cp-contact/done',
    name: 'cp_contact_done',
    components: {
      default: CpContactDone
    }
  },
  {
    path: '/contact',
    name: 'contact',
    components: {
      default: Contact
    }
  },
  {
    path: '/contact/done',
    name: 'contact_done',
    components: {
      default: ContactDone
    }
  }
];

export default routes;
